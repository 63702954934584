import React, {useEffect, useState} from 'react';
import './App.css';
import {Input} from "./components/ui/input";
import axios, {AxiosError, HttpStatusCode} from "axios";
import {Button} from "./components/ui/button";
import {Card, CardContent} from "./components/ui/card";
import {Trash} from "lucide-react";
import throttle from 'lodash.throttle';
import {Popover, PopoverContent, PopoverTrigger} from './components/ui/popover';
import {Label} from './components/ui/label';
import {Checkbox} from './components/ui/checkbox';
// @ts-ignore
import ShowMoreText from "react-show-more-text";
import ReactPaginate from "react-paginate";

function App() {
    const [query, setQuery] = useState('')
    const [reviews, setReviews] = useState([])
    const [key, setKey] = useState('')
    const [reqRemove, setReqRemove] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const itemsPerPage = 20
    const fetchData = throttle(() => {
        axios.post(`https://reviewcongty.blog/backend/review/admin/list?page=${page}&size=${itemsPerPage}`, {
            company_name: query?.trim(),
            req_remove: reqRemove
        }).then(resp => {
            if (resp.status === HttpStatusCode.Ok) {
                setReviews(resp.data?.reviews)
                setTotal(resp.data?.total ?? 0)
            } else setReviews([])
        })
    }, 200, {trailing: true})

    const removeReview = (review_id: number) => {
        setError('')
        axios.delete('https://reviewcongty.blog/backend/review', {
            data: {
                review_id: review_id,
                key: key?.trim(),
            }
        }).then(resp => {
            if (resp.status === HttpStatusCode.Ok) {
                fetchData()
            }
        }).catch((e: AxiosError) => {
            // @ts-ignore
            setError(e.response?.data?.error)
        })
    }

    const handlePageClick = (event: any) => {
        setPage(event.selected ?? 0)
    };

    useEffect(() => {
        fetchData()
    }, [reqRemove, page]);

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <div className="flex justify-center w-[100vw]">
            <div className={'max-w-screen-md w-full py-8 px-2'}>
                <div className={'flex gap-3'}>
                    <Input value={query} onChange={e => setQuery(e.target.value)} placeholder={'Nhập tên công ty'}/>
                    <Button onClick={() => {
                        if (page === 0) {
                            fetchData()
                        } else setPage(0)
                    }}>Tìm kiếm</Button>
                </div>
                <div className="flex items-center space-x-2 mb-6 mt-2">
                    <Checkbox checked={reqRemove} onCheckedChange={(ch: any) => setReqRemove(ch)} id="terms"/>
                    <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        Chỉ hiện những review yêu cầu xóa
                    </label>
                </div>

                <div>
                    <ReactPaginate
                        className={'flex gap-3 my-3'}
                        activeClassName={'underline text-blue-500'}
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={Math.ceil(total / itemsPerPage)}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                    />
                </div>

                {error ? <div className={'mb-2 text-red-600'}>Lỗi: {error}</div> : null}

                <div className={'flex flex-col gap-3'}>
                    {reviews.map((e: any) => (
                        <Card key={e.review_id} className={'p-2'}>
                            <CardContent className={'flex gap-3 items-center'}>
                                <img src={e.company?.thumb_image} alt={e.company?.name}
                                     className={'object-cover h-10 w-10'}/>
                                <div className={'flex-1 flex flex-col gap-3'}>
                                    <ShowMoreText
                                        /* Default options */
                                        lines={3}
                                        more="Show more"
                                        less="Show less"
                                        className="content-css"
                                        anchorClass="show-more-less-clickable"
                                        onClick={() => {
                                        }}
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >
                                        <span>{e.content}</span>
                                    </ShowMoreText>
                                    <span className={'font-semibold'}>Công ty: {e.company?.name}</span>
                                    {e.reason_remove ? <div>Lý do yêu cầu xóa: <span
                                        className={'text-blue-600'}>{e.reason_remove}</span></div> : null}
                                </div>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <div className={'cursor-pointer'}>
                                            <Trash color={'red'}/>
                                        </div>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-80">
                                        <div className="grid gap-4">
                                            <div className="grid gap-2">
                                                <div className="flex flex-col gap-1">
                                                    <Label htmlFor="width">Nhập mã xóa review</Label>
                                                    <Input
                                                        value={key}
                                                        onChange={e => setKey(e.target.value)}
                                                        defaultValue="100%"
                                                        className="col-span-2 h-8 mb-2"
                                                    />
                                                    <Button onClick={() => removeReview(e?.review_id)}>Xóa</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </PopoverContent>
                                </Popover>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default App;
